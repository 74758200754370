<template>
  <div id="history" class="fill-height">
    <Navbar />
      <v-container fluid class="pt-0 pb-0 fill-height">
        <v-row class="fill-height">
          <v-col cols="12" sm="12" order="2" order-sm="2" order-md="1" order-lg="1">
            <ckeditor v-model="editorData" :config="editorConfig"></ckeditor>
          </v-col>

          <v-btn color="cyan" @click="fn_cek()">cek</v-btn>
          <v-btn color="cyan" @click="saveImpor()">saveImpor</v-btn>
        </v-row>
    </v-container>
   </div> 
</template>

<script>
import Navbar from '@/components/Navbar'

//import VueSanitize
//import CKEditor from  'ckeditor4-vue';
import { api } from '@/components/Apiku'
import axios from 'axios'
export default {
  data: () => ({
    textKu : "soal@Content of the editor. pilih@a. Do you have any   pilih@b. Do you have any experience with ",
    editorData: '<p>Copy dan paste soal di sini</p> ',
    editorConfig: {
      toolbar: [ [ 'Bold','Image']],
      height: '500px',
      width:'800px',
	
    },dtImporWord:[],
    konfirmHapus:false, is_pub:true, st_pub:null,
    konfirmHapus2:false, ftKelasLokal:[],totalSkor:0,
    snackbar: false, lsKelasLokal :[],kelasLokal_id : [],
    
    image: {
    uploadURL: "/api/myEndpoint",
    dropzoneOptions: {}
  },
 
  
  }),
  components:{
    Navbar
  },
  
  methods: {
    fnExcel() {
      
    }, 
    fn_cek(){
      const datax = this.editorData.split("soal@")
      if(datax.length > 1){
        let i = 1
        datax.forEach((value ) => {
          if(value.length >=5){
            const cek_soal=value.split("pilih@a.")
            const soal = cek_soal[0]
            if(cek_soal[1].length>2){
              const pilihan = cek_soal[1].split("kunci@")
              let opsi = pilihan [0]

              let opsi_a = opsi.split("pilih@b.")
              //const regex = '<p>/Dog/ig/</p>'
              let pil_a = opsi_a[0].replaceAll('<p>',"").replaceAll('</p>',"").replaceAll('/n',"").replaceAll('\n',"")
              let opsi_b = opsi_a[1].split("pilih@c.")
              let opsi_c = opsi_b[1].split("pilih@d.")
              let opsi_d = opsi_c[1].split("pilih@e.")
              let opsi_e = opsi_d[1]

              console.log(' cek a :', pil_a, ' cek b :', opsi_b[0], ' cek c :', opsi_c[0], 
              ' cek d :', opsi_d[0], ' cek e :', opsi_e)
              const t_kunci = pilihan[1].split("n_benar@")
              let kunci = t_kunci[0].replaceAll('<p>',"").replaceAll('</p>',"").replaceAll('/n',"").replaceAll('\n',"")
              let n_benar = t_kunci[1].replaceAll('<p>',"").replaceAll('</p>',"").replaceAll('/n',"").replaceAll('\n',"")
              n_benar = n_benar.replaceAll('</p>','')
              console.log('cek jawab', this.$sanitize(t_kunci[0]), ' kunci baru ', kunci, ' n_benar ',n_benar )
              console.log('cek soal ', cek_soal, soal, ' pilihan ', t_kunci , i)
              const dataku = {
                soal: soal,
                a: pil_a,
                b: opsi_b[0].replaceAll('<p>',"").replaceAll('</p>',"").replaceAll('/n',"").replaceAll('\n',""),
                c: opsi_c[0].replaceAll('<p>',"").replaceAll('</p>',"").replaceAll('/n',"").replaceAll('\n',""),
                d: opsi_d[0].replaceAll('<p>',"").replaceAll('</p>',"").replaceAll('/n',"").replaceAll('\n',""),
                e: opsi_e.replaceAll('<p>',"").replaceAll('</p>',"").replaceAll('/n',"").replaceAll('\n',""),
                kunci: kunci,
                n_benar: n_benar,
                n_salah: 0,
              }
              this.dtImporWord.push(dataku)
            }
          }
          i++
        })
        console.log('cek data ', this.dtImporWord)
      }
    },
    saveImpor() {
      const cekImpor = Number(this.dtImporWord.length);
      if (cekImpor < 1) {
        this.text = 'Data kosong'
        this.snackbar =true
      } else {
        const dataku = {
          ujian_id: 8,
          data:this.dtImporWord
        }
        //console.log('cek data excel ', dataku )
        axios
          .post(api + 'imporSoal', dataku)
          .then(response => {
            //console.log('cek ', response.data)
            if (response.data.cek === 1) {
              this.text = 'Data tersimpan'
              this.snackbar=true
              this.dialogImpor = false
              this.fnDTSoal()             
            } else {
              //console.log(' tidak sukses ')
            }
          })
      }
    },
   

  },
  mounted() {
    setTimeout(() => {
      this.loading = false
      this.searchTextsLoading = false
    }, 2000)

    
  }
}
</script>